import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import YoutubeIframe from '@techassi/vue-youtube-iframe';
import CKEditor from '@ckeditor/ckeditor5-vue';

createApp(App)
    .use(store)
    .use(router)
    .use(YoutubeIframe)
    .use(CKEditor)
    .mount('#root')
