<template>
  <div class="home">
    <div class="container">
      <groups-video v-if="groupsVideoList && (authorized==1  || authorized==3)" :groupsvideo="groupsVideoList"/>
      <div class="row"  v-if="authorized && (authorized==2)" style="background-color: brown;">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="card card-stats">
                <div class="card-header card-header-warning card-header-icon">
                  <h3 class="card-title">
                    <small style="color:black">Limited access. You have no permission</small>
                  </h3>
                </div>
              </div>
              </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .container {
    display: flex;
    flex-direction: column;
  }
</style>

<script>
import { mapMutations, mapActions } from "vuex";
import GroupsVideo from '@/components/GroupsVideo.vue'

export default {
  name: 'Home',
  data() {
    return {
      groupsVideoList: false,
      groupsList: false,
      k:0
    }
  },
  components: {
    GroupsVideo
  },
  computed: {
    authorized() {
       return this.$store.getters['getAuhtState'];
    },
  },
  mounted() {
    this.doAuthorize();
    
    this.getVideos()
    
  },
  methods: {
    ...mapActions(["doAuthorize"]),
    ...mapMutations([
      "setError",
      "setMsg"
    ]),
    getVideos() {
    
      var list_of_groups=[]
      fetch('/api/videos/getvideobygroupsids', {
        method: 'GET'
      })
      .then(async r => {
        let response = await r.json()

        //get groups information
        response.map(function(value) {
          list_of_groups.push(value._id);
        });


        if (response.error) {
          
          this.setError(response)
          return
        }
      
        this.setMsg('Groups, Videos obtained');
        this.groupsVideoList = response;
      })

      //groups
       fetch('/api/videosgroup/list', {
          method: 'GET'        
        })
            .then(async r => {
              let responseGroup = await r.json()

              if (responseGroup.error) {
              
                this.setError(responseGroup)
                return
              }
             
              this.setMsg('Groups obtained');

              this.groupsList = responseGroup;
            })
          }
  }
}

</script>
