import { createStore } from 'vuex'
import router from '../router';

let errTimeout;
let msgTimeout;

export default createStore({
  state: {
    hasError: false,
    msg: false
  },
  mutations: {
    setError: (state, error) => {
      state.hasError = false
      errTimeout && clearTimeout(errTimeout);
      errTimeout = setTimeout(() => state.hasError = error ? {error: error.error} : false, 100);
      if (error.status && error.status == 401) {
        router.push('/signin');
      }
    },
    setMsg: (state, msg) => {
      state.msg = false
      msgTimeout && clearTimeout(msgTimeout);
      msgTimeout = setTimeout(() => state.msg = msg ? msg : false, 100);
    }
  },
  getters: {
    getAuhtState(state) {
      return state.role;
    },
  },
  actions: {

    doSignin({ commit }, context) {
          
      fetch('/auth/signin', {
        method: 'POST',
        body: JSON.stringify(context)
      })
      .then(async r => {
        let response = await r.json()
        if (response.error) {
          commit('setLoading', false);
          commit('setError', response.error);
          if (response.status ==400){
            commit('setMsg', 'Check login and password! No user with these data');
          } else  if (response.status ==401){
            commit('setMsg', 'Unauthorized');
          }
          return
        }
        if(!response.error){
          commit('setMsg', '');
        }
        commit('setMsg', 'Signed is successeful');

        router.push('/');
      })
    },


    doAuthorize(context) {

      fetch('/auth/role', {
        method: 'GET'
      })
      .then(async r => {
        let response = await r.json()
        if (response.error) {
          context.state.role = 0;
          if (response.status ==401){
            context.state.role = 0;
          }
          return
        }
        if(response.role){
          if(response.role == 'admin'){ 
            context.state.role = 1;
          } else if (response.role == 'gopher'){
            context.state.role = 2 ;
          } else if (response.role == 'sales'){
            context.state.role = 3;
          } else {
            context.state.role = 0;
          }
        } else {
          context.state.role = 0;
        }
      })
    },
  
    signout({ commit }, context) {
      commit('signout');
      fetch(`/auth/signout`, {
        method: "GET"
      })
        .then(async r => {
          let response = await r.json()
          console.assert(response)
          context.state.role = 0;
      })     
      router.push('/signin');
    }
  },
  modules: {
  }
})
