<template>
  <div class="wrapper">
    <Menu />
    <router-view />
  </div>
</template>


<style src="@/assets/less/main.less" lang="less"></style>
<style src="@/assets/less/form.less" lang="less"></style>

<script>
import Menu from '@/components/Menu.vue'

export default {
  name: 'Toolbar',
  components: {
    Menu
  }
}
</script>