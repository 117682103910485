import { createRouter, createWebHistory } from 'vue-router'


import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  
  {
    path: '/signin',
    name: 'Signin',
    component: () => import(/* webpackChunkName: "Signin" */ '../views/Signin.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "Profile" */ '../views/Profile.vue')
  },
  {
    path: '/group/:groupRouteId',
    name: 'Group',
    props: true,
    component: () => import(/* webpackChunkName: "Group" */ '../views/Group.vue')
  },
  {
    path: '/video/:videoRouteId',
    name: 'Video',
    props: true,
    component: () => import(/* webpackChunkName: "Video" */ '../views/Video.vue')
  },
  {
    path: '/videos/list',
    name: 'List of Videos',
    props: true,
    component: () => import(/* webpackChunkName: "Video" */ '../views/ListVideos.vue')
  },
  {
    path: '/video/add',
    name: 'Add Video',
    props: true,
    component: () => import(/* webpackChunkName: "Video" */ '../views/AddVideo.vue')
  },
  {
    path: '/videosgroup/list',
    name: 'List of Groups',
    props: true,
    component: () => import(/* webpackChunkName: "Group" */ '../views/ListGroups.vue')
  },
  {
    path: '/videosgroup/fetured',
    name: 'Group Featured',
    props: true,
    component: () => import(/* webpackChunkName: "Group" */ '../views/FeatureGroup.vue')
  },
  {
    path: '/videosgroup/add',
    name: 'Add Group',
    props: true,
    component: () => import(/* webpackChunkName: "Video" */ '../views/AddGroup.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/signin'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
 
  next();
});

export default router
