
<template>
  <header class="header">
    <div class="container">
      <div class="spinner" :class="loading ? 'spin' : 'idle'" style ="margin-top: -10px;">
        <router-link to="/">
          <img src="@/assets/loading.svg" class="sp" />
          <img src="@/assets/television.svg" />
        </router-link>
      </div>
      <nav  class="navbar navbar-expand-lg bg-secondary">
        <div class="dropdown">
          <button>VIDEOS</button>
          <div class="dropdown-content">
            <router-link class="btn btn-link" to="/">
               BY GROUP
            </router-link>
            <router-link class="btn btn-link" to="/videos/list">
               WITHOUT GROUP
            </router-link>
            <router-link class="btn btn-link" to="/video/add">
              ADD VIDEO
            </router-link>
          </div>
        </div>

        <div class="dropdown">
          <button>GROUPS</button>
          <div class="dropdown-content">
              <router-link class="btn btn-link" to="/videosgroup/list">
                ALL GROUPS
              </router-link>
              <router-link class="btn btn-link" to="/videosgroup/fetured">
               FEATURE VIDEOS
              </router-link>
              <router-link class="btn btn-link" to="/videosgroup/add">
                ADD GROUP
              </router-link>
          </div>
        </div>

        <div class="dropdown">
          <button>USERS SETTINGS</button>
          <div class="dropdown-content">
            <router-link class="btn btn-link" to="/profile">
              MY PROFILE
            </router-link>
          </div>
        </div>

      </nav>
      <div class="messages">
        <span v-if=hasError class="error">{{hasError.error}}</span> 
        <span v-if=msg class="msg">{{msg}}</span>
      </div>
    </div>
  </header>
</template>
<style src="@/assets/less/header.less" lang="less"></style>

<script>
import { mapState } from 'vuex';

export default {
  name: "Menu",
  mounted() {
  },
  computed: {
    ...mapState([
      'hasError',
      'msg'
    ])
  },
  methods: {

  },
};
</script>